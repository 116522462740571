const title = 'Atencion-Medico';
const aseguradoraRoutes = [
  {
    path: 'atencion/medico',
    name: 'atencion-medico-index',
    component: () => import('../views/index.vue'),
    meta: {
      title,
    },
  },
  {
    path: 'atencion/medico/edit',
    name: 'atencion-medico-edit',
    component: () => import('../views/edit.vue'),
    meta: {
      title: `${title} | Editar`,
    },
  },
];
export default aseguradoraRoutes;
