import { getField, updateField } from 'vuex-map-fields';
import axios from '@/modules/common/axiosERP';
import { setUser } from '../../../boot/sentry';
import getterDashboard from '../privilegios/dashboard';
import getterCalendario from '../privilegios/calendario';
import getterPaciente from '../privilegios/paciente';
import getterMedico from '../privilegios/medico';
import getterAseguradora from '../privilegios/aseguradora';
import getterAnticipo from '../privilegios/anticipo';
import getterCotizacion from '../privilegios/cotizacion';
import getterTratamiento from '../privilegios/tratamiento';
import getterVentas from '../privilegios/ventas';
import getterReportes from '../privilegios/reportes';
import getterParametros from '../privilegios/parametros';
import getterExtras from '../privilegios/extras/empresa_extras';

const logoDefault = '@/assets/images/logo.png';

export default {
  namespaced: true,
  state: () => ({
    company: {},
    user: {},

    funcionalidades: [],
    bloqueados: [],
    userBloqueados: [],
    paquetePrivilegios: [],
    userPrivilegios: [],
    extras: [],
    empresa_extras: [],

    funcionalidadAccions: [],
    companyPrivilegios: [],
    extrafacturaElectronica: false,
  }),
  mutations: {
    updateField,
    setCompany: (state, company) => { state.company = company; },
    setUser: (state, user) => { state.user = user; },

    setFuncionalidades: (state, data) => { state.funcionalidades = data; },
    setBloqueados: (state, data) => { state.bloqueados = data; },
    setUserBloqueados: (state, data) => { state.userBloqueados = data; },
    setPaquetePrivilegios: (state, data) => { state.paquetePrivilegios = data; },
    setUserPrivilegios: (state, data) => { state.userPrivilegios = data; },
    setExtras: (state, data) => { state.extras = data; },
    setEmpresaExtras: (state, data) => { state.empresa_extras = data; },
    setCompanyPrivilegios: (state, data) => { state.companyPrivilegios = data; },
    setExtrafacturaElectronica: (state, data) => { state.extrafacturaElectronica = data; },

    setFuncionalidadAccions(state, data) {
      const PAQUETE_PRIVILEGIOS = [];
      data.forEach(({ id, name, action }) => {
        PAQUETE_PRIVILEGIOS[`${name}`] = { funcionalidad_id: `${id}`, accion: `${action}` };
      });
      state.funcionalidadAccions = PAQUETE_PRIVILEGIOS;
    },
  },
  actions: {
    async getData({ commit }) {
      const RESPONSE = await axios.get('/clinic/main');
      const { data } = RESPONSE.data;
      const MODULOS = RESPONSE.data.data.modulos;
      const { bloqueados: BLOQUEADOS } = RESPONSE.data.data;
      const { user_bloqueados: USER_BLOQUEADOS } = RESPONSE.data.data;
      const { paquete_privilegios: PAQUETE_PRIVILEGIOS } = RESPONSE.data.data;
      const { user_privilegios: USER_PRIVILEGIOS } = RESPONSE.data.data;
      const { extras: EXTRAS } = RESPONSE.data.data;
      const { empresa_extras: EMPRESA_EXTRAS } = RESPONSE.data.data;
      const { company_privileges: COMPANY_PRIVILEGES } = RESPONSE.data.data;
      const { empresa_factura_electronica: EMPRESA_FACTURA_ELECTRONICA } = RESPONSE.data.data;

      let funcionalidades = [];
      if (MODULOS.length > 0) {
        funcionalidades = MODULOS[0].funcionalidades;
        funcionalidades.sort((a, b) => {
          if (a.orden > b.orden) {
            return 1;
          }
          if (a.orden < b.orden) {
            return -1;
          }
          // si a es igual a b
          return 0;
        });
      }
      if (EMPRESA_FACTURA_ELECTRONICA.length > 0) {
        const EXTRA_FACTURA_ELECTRONICA = EMPRESA_FACTURA_ELECTRONICA.findIndex((ee) => ((ee.factura_electronica_id === 2) && ee.activar === 1)) >= 0;
        commit('setExtrafacturaElectronica', EXTRA_FACTURA_ELECTRONICA);
      }
      commit('setCompany', data.company);
      commit('setUser', data.user);

      commit('setFuncionalidades', funcionalidades);
      commit('setBloqueados', BLOQUEADOS);
      commit('setUserBloqueados', USER_BLOQUEADOS);
      commit('setPaquetePrivilegios', PAQUETE_PRIVILEGIOS);
      commit('setUserPrivilegios', USER_PRIVILEGIOS);
      commit('setExtras', EXTRAS);
      commit('setEmpresaExtras', EMPRESA_EXTRAS);
      commit('setCompanyPrivilegios', COMPANY_PRIVILEGES);
      commit('setFuncionalidadAccions', PAQUETE_PRIVILEGIOS);
      setUser(data.user, data.company);
    },
  },
  getters: {
    getField,
    getCompany: (state) => state.company,
    getPathLogo(state) {
      const logoPath = state.company.logo;
      if (logoPath) {
        return process.env.VUE_APP_BASE + logoPath;
      }
      return logoDefault;
    },
    getPathImgUser(state) {
      const logoPath = state.user.imagen;
      if (logoPath) {
        return `${process.env.VUE_APP_BASE}/${logoPath}`;
      }
      return logoDefault;
    },
    getFuncionalidades: (state) => state.funcionalidades,
    getFuncionalidadesTopNav(state) {
      return state.funcionalidades.filter((el) => el.orden !== 0);
    },
    getFuncionalidadesDropDown(state) {
      return state.funcionalidades.filter((el) => el.orden === 0);
    },

    getCompanyPrivilegios(state) {
      return state.companyPrivilegios;
    },
    getFuncionalidadAccions: (state) => state.funcionalidadAccions,
    ...getterDashboard,
    ...getterCalendario,
    ...getterPaciente,
    ...getterMedico,
    ...getterAseguradora,
    ...getterAnticipo,
    ...getterCotizacion,
    ...getterTratamiento,
    ...getterVentas,
    ...getterReportes,
    ...getterParametros,
    ...getterExtras,
  },
};
