import { getField, updateField } from 'vuex-map-fields';

const ESTADO_INICIAL = {
  turnoId: 0,
  listaConsultorio: [],
  tiempoDescanso: '00:00',
  checkLunes: false,
  checkMartes: false,
  checkMiercoles: false,
  checkJueves: false,
  checkViernes: false,
  checkSabado: false,
  checkDomingo: false,
  lunesIngreso: '',
  martesIngreso: '',
  miercolesIngreso: '',
  juevesIngreso: '',
  viernesIngreso: '',
  sabadoIngreso: '',
  domingoIngreso: '',
  lunesSalida: '',
  martesSalida: '',
  miercolesSalida: '',
  juevesSalida: '',
  viernesSalida: '',
  sabadoSalida: '',
  domingoSalida: '',
  lunesPacientes: '',
  martesPacientes: '',
  miercolesPacientes: '',
  juevesPacientes: '',
  viernesPacientes: '',
  sabadoPacientes: '',
  domingoPacientes: '',
  lunesTiempoAtencion: '',
  martesTiempoAtencion: '',
  miercolesTiempoAtencion: '',
  juevesTiempoAtencion: '',
  viernesTiempoAtencion: '',
  sabadoTiempoAtencion: '',
  domingoTiempoAtencion: '',
  lunesConsultorioSelected: null,
  martesConsultorioSelected: null,
  miercolesConsultorioSelected: null,
  juevesConsultorioSelected: null,
  viernesConsultorioSelected: null,
  sabadoConsultorioSelected: null,
  domingoConsultorioSelected: null,
};

export default {
  namespaced: true,
  state: { ...ESTADO_INICIAL },
  getters: {
    getField,
    turno2Validate(state) {
      if (
        state.checkLunes
        || state.checkMartes
        || state.checkMiercoles
        || state.checkJueves
        || state.checkViernes
        || state.checkSabado
        || state.checkDomingo
      ) {
        return true;
      }
      return false;
    },
    getDataModalTurno2(state) {
      if (state.turnoId === 0) {
        if (
          state.lunesIngreso === '' && state.lunesSalida === '' && state.lunesPacientes === ''
          && state.martesIngreso === '' && state.martesSalida === '' && state.martesPacientes === ''
          && state.miercolesIngreso === '' && state.miercolesSalida === '' && state.miercolesPacientes === ''
          && state.juevesIngreso === '' && state.juevesSalida === '' && state.juevesPacientes === ''
          && state.viernesIngreso === '' && state.viernesSalida === '' && state.viernesPacientes === ''
          && state.sabadoIngreso === '' && state.sabadoSalida === '' && state.sabadoPacientes === ''
          && state.domingoIngreso === '' && state.domingoSalida === '' && state.domingoPacientes === ''
        ) {
          return null;
        }
      }
      return {
        id: state.turnoId,
        numero_turno: 2,
        lunesIngreso: state.lunesIngreso,
        martesIngreso: state.martesIngreso,
        miercolesIngreso: state.miercolesIngreso,
        juevesIngreso: state.juevesIngreso,
        viernesIngreso: state.viernesIngreso,
        sabadoIngreso: state.sabadoIngreso,
        domingoIngreso: state.domingoIngreso,
        lunesSalida: state.lunesSalida,
        martesSalida: state.martesSalida,
        miercolesSalida: state.miercolesSalida,
        juevesSalida: state.juevesSalida,
        viernesSalida: state.viernesSalida,
        sabadoSalida: state.sabadoSalida,
        domingoSalida: state.domingoSalida,
        lunesPacientes: state.lunesPacientes,
        martesPacientes: state.martesPacientes,
        miercolesPacientes: state.miercolesPacientes,
        juevesPacientes: state.juevesPacientes,
        viernesPacientes: state.viernesPacientes,
        sabadoPacientes: state.sabadoPacientes,
        domingoPacientes: state.domingoPacientes,
        lunesTiempoAtencion: state.lunesTiempoAtencion,
        martesTiempoAtencion: state.martesTiempoAtencion,
        miercolesTiempoAtencion: state.miercolesTiempoAtencion,
        juevesTiempoAtencion: state.juevesTiempoAtencion,
        viernesTiempoAtencion: state.viernesTiempoAtencion,
        sabadoTiempoAtencion: state.sabadoTiempoAtencion,
        domingoTiempoAtencion: state.domingoTiempoAtencion,
        lunesConsultorioSelected: state.lunesConsultorioSelected,
        martesConsultorioSelected: state.martesConsultorioSelected,
        miercolesConsultorioSelected: state.miercolesConsultorioSelected,
        juevesConsultorioSelected: state.juevesConsultorioSelected,
        viernesConsultorioSelected: state.viernesConsultorioSelected,
        sabadoConsultorioSelected: state.sabadoConsultorioSelected,
        domingoConsultorioSelected: state.domingoConsultorioSelected,
        tiempoDescanso: state.tiempoDescanso,
      };
    },
  },
  mutations: {
    updateField,
    loadModalTurno2(state, data) {
      const charsToDelete = 3; // para reformatear el tiempo que llega HH:mm:ss
      state.turnoId = data.id;
      const TD = data.tiempo_descanso ?? '';
      if (TD !== '') {
        state.tiempoDescanso = TD.substring(0, TD.length - charsToDelete);
      }
      const TI_LUNES = data.ti_lunes ?? '';
      const TI_MARTES = data.ti_martes ?? '';
      const TI_MIERCOLES = data.ti_miercoles ?? '';
      const TI_JUEVES = data.ti_jueves ?? '';
      const TI_VIERNES = data.ti_viernes ?? '';
      const TI_SABADO = data.ti_sabado ?? '';
      const TI_DOMINGO = data.ti_domingo ?? '';
      const TS_LUNES = data.ts_lunes ?? '';
      const TS_MARTES = data.ts_martes ?? '';
      const TS_MIERCOLES = data.ts_miercoles ?? '';
      const TS_JUEVES = data.ts_jueves ?? '';
      const TS_VIERNES = data.ts_viernes ?? '';
      const TS_SABADO = data.ts_sabado ?? '';
      const TS_DOMINGO = data.ts_domingo ?? '';
      if (TI_LUNES !== '' && TS_LUNES !== '') {
        state.lunesIngreso = TI_LUNES.substring(0, TI_LUNES.length - charsToDelete);
        state.lunesSalida = TS_LUNES.substring(0, TS_LUNES.length - charsToDelete);
        state.checkLunes = true;
      }
      if (TI_MARTES !== '' && TS_MARTES !== '') {
        state.martesIngreso = TI_MARTES.substring(0, TI_MARTES.length - charsToDelete);
        state.martesSalida = TS_MARTES.substring(0, TS_MARTES.length - charsToDelete);
        state.checkMartes = true;
      }
      if (TI_MIERCOLES !== '' && TS_MIERCOLES !== '') {
        state.miercolesIngreso = TI_MIERCOLES.substring(0, TI_MIERCOLES.length - charsToDelete);
        state.miercolesSalida = TS_MIERCOLES.substring(0, TS_MIERCOLES.length - charsToDelete);
        state.checkMiercoles = true;
      }
      if (TI_JUEVES !== '' && TS_JUEVES !== '') {
        state.juevesIngreso = TI_JUEVES.substring(0, TI_JUEVES.length - charsToDelete);
        state.juevesSalida = TS_JUEVES.substring(0, TS_JUEVES.length - charsToDelete);
        state.checkJueves = true;
      }
      if (TI_VIERNES !== '' && TS_VIERNES !== '') {
        state.viernesIngreso = TI_VIERNES.substring(0, TI_VIERNES.length - charsToDelete);
        state.viernesSalida = TS_VIERNES.substring(0, TS_VIERNES.length - charsToDelete);
        state.checkViernes = true;
      }
      if (TI_SABADO !== '' && TS_SABADO !== '') {
        state.sabadoIngreso = TI_SABADO.substring(0, TI_SABADO.length - charsToDelete);
        state.sabadoSalida = TS_SABADO.substring(0, TS_SABADO.length - charsToDelete);
        state.checkSabado = true;
      }
      if (TI_DOMINGO !== '' && TS_DOMINGO !== '') {
        state.domingoIngreso = TI_DOMINGO.substring(0, TI_DOMINGO.length - charsToDelete);
        state.domingoSalida = TS_DOMINGO.substring(0, TS_DOMINGO.length - charsToDelete);
        state.checkDomingo = true;
      }
      state.lunesPacientes = data.paciente_por_dia_lunes ?? '';
      state.martesPacientes = data.paciente_por_dia_martes ?? '';
      state.miercolesPacientes = data.paciente_por_dia_miercoles ?? '';
      state.juevesPacientes = data.paciente_por_dia_jueves ?? '';
      state.viernesPacientes = data.paciente_por_dia_viernes ?? '';
      state.sabadoPacientes = data.paciente_por_dia_sabado ?? '';
      state.domingoPacientes = data.paciente_por_dia_domingo ?? '';
      const TA_LUNES = data.tiempo_atencion_lunes ?? '';
      const TA_MARTES = data.tiempo_atencion_martes ?? '';
      const TA_MIERCOLES = data.tiempo_atencion_miercoles ?? '';
      const TA_JUEVES = data.tiempo_atencion_jueves ?? '';
      const TA_VIERNES = data.tiempo_atencion_viernes ?? '';
      const TA_SABADO = data.tiempo_atencion_sabado ?? '';
      const TA_DOMINGO = data.tiempo_atencion_domingo ?? '';
      if (TA_LUNES !== '') {
        state.lunesTiempoAtencion = TA_LUNES;
      }
      if (TA_MARTES !== '') {
        state.martesTiempoAtencion = TA_MARTES;
      }
      if (TA_MIERCOLES !== '') {
        state.miercolesTiempoAtencion = TA_MIERCOLES;
      }
      if (TA_JUEVES !== '') {
        state.juevesTiempoAtencion = TA_JUEVES;
      }
      if (TA_VIERNES !== '') {
        state.viernesTiempoAtencion = TA_VIERNES;
      }
      if (TA_SABADO !== '') {
        state.sabadoTiempoAtencion = TA_SABADO;
      }
      if (TA_DOMINGO !== '') {
        state.domingoTiempoAtencion = TA_DOMINGO;
      }
      if (data.piso_consultorio_id_lunes !== null) {
        state.lunesConsultorioSelected = state.listaConsultorio.find((el) => (
          el.id === data.piso_consultorio_id_lunes
        ));
      }
      if (data.piso_consultorio_id_martes !== null) {
        state.martesConsultorioSelected = state.listaConsultorio.find((el) => (
          el.id === data.piso_consultorio_id_martes
        ));
      }
      if (data.piso_consultorio_id_miercoles !== null) {
        state.miercolesConsultorioSelected = state.listaConsultorio.find((el) => (
          el.id === data.piso_consultorio_id_miercoles
        ));
      }
      if (data.piso_consultorio_id_jueves !== null) {
        state.juevesConsultorioSelected = state.listaConsultorio.find((el) => (
          el.id === data.piso_consultorio_id_jueves
        ));
      }
      if (data.piso_consultorio_id_viernes !== null) {
        state.viernesConsultorioSelected = state.listaConsultorio.find((el) => (
          el.id === data.piso_consultorio_id_viernes
        ));
      }
      if (data.piso_consultorio_id_sabado !== null) {
        state.sabadoConsultorioSelected = state.listaConsultorio.find((el) => (
          el.id === data.piso_consultorio_id_sabado
        ));
      }
      if (data.piso_consultorio_id_domingo !== null) {
        state.domingoConsultorioSelected = state.listaConsultorio.find((el) => (
          el.id === data.piso_consultorio_id_domingo
        ));
      }
    },
  },
  actions: {
    resetModalTurno2({ commit }) {
      Object.entries(ESTADO_INICIAL).forEach(([key, value]) => {
        commit('updateField', { path: key, value });
      });
    },
  },
};
