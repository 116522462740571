import axios from '@/modules/common/axiosERP';
import { util } from '@/plugins/util';
import { MODALIDAD_FACTURACION } from '@/utils/globalConventions';
import validarParametroFacturacionSiat from '@/store/modules/facturacion/utils/siatValidations';

function filterModalidad(modalidades, extrafacturaElectronica) {
  if (!extrafacturaElectronica) {
    const indexComputarizaLinea = modalidades.findIndex((mf) => mf.id === 3);
    const indexElectronicaLinea = modalidades.findIndex((mf) => mf.id === 4);
    if (indexComputarizaLinea >= 0) {
      modalidades.splice(indexComputarizaLinea, 1);
    }
    if (indexElectronicaLinea >= 0) {
      modalidades.splice(indexElectronicaLinea, 1);
    }
  }
  return modalidades;
}
function filterTipoEmision(lista, extrafacturaElectronica) {
  const tipoEmisions = [...lista];
  if (extrafacturaElectronica) {
    const indexEmisionMasiva = tipoEmisions.findIndex((el) => el.cod_clasificador === 3);
    if (indexEmisionMasiva > 0) {
      tipoEmisions.splice(indexEmisionMasiva, 1);
    }
  }
  return tipoEmisions;
}
export default async function callCreateApi({ rootState, commit, rootGetters }) {
  try {
    const RESPONSE = await axios.get('/clinic/invoicing/create');
    const { aseguradoras } = RESPONSE.data.data;
    const { medicos } = RESPONSE.data.data;
    const { monedas } = RESPONSE.data.data;
    const { sucursal } = RESPONSE.data.data;
    const FORMAS_DE_PAGO = RESPONSE.data.data.tipo_forma_pagos;
    const MEDIOS_DE_PAGO = RESPONSE.data.data.tipo_medio_pagos;
    const CATEGORIA_SERVICIOS = RESPONSE.data.data.categoria_servicios;
    const MEDIO_PAGO_CREDITO = RESPONSE.data.data.medio_pago_credito;
    const TIPO_DOCUMENTOS = RESPONSE.data.data.tipo_documentos;
    const LISTA_CONTINGENCIAS = validarParametroFacturacionSiat(
      RESPONSE.data.data.tipo_factura_contingencias,
      false,
      rootState.main.extrafacturaElectronica,
    );
    const LISTA_TIPO_EMISIONS = validarParametroFacturacionSiat(
      RESPONSE.data.data.tipo_emisions,
      true,
      rootState.main.extrafacturaElectronica,
    );
    const LISTA_TIPO_EMISIONS_FILTERED = filterTipoEmision(
      LISTA_TIPO_EMISIONS,
      rootState.main.extrafacturaElectronica,
    );
    const TIPO_EMISION_SELECTED = LISTA_TIPO_EMISIONS_FILTERED.length > 0 ? LISTA_TIPO_EMISIONS_FILTERED[0] : null;

    const LISTA_PUNTO_VENTAS = validarParametroFacturacionSiat(
      RESPONSE.data.data.siat_punto_ventas,
      false,
      rootState.main.extrafacturaElectronica,
    );

    let modalidadesFacturacion = filterModalidad(
      RESPONSE.data.data.modalidades_configuracion,
      rootState.main.extrafacturaElectronica,
    );
    if (rootGetters['main/isBloqueadoFacturaManual']) {
      modalidadesFacturacion = modalidadesFacturacion.filter((mod) => (
        mod.id !== MODALIDAD_FACTURACION.MANUAL
      ));
    }
    const modalidadSinFactura = { id: MODALIDAD_FACTURACION.SIN_FACTURA, nombre: 'Sin Factura' };
    modalidadesFacturacion.push(modalidadSinFactura);
    const MEDIO_PAGO_AJUSTE_ANTICIPO = RESPONSE.data.data.medioPagoAjuste;

    commit('setSucursal', sucursal);
    commit('formaPagoModal/setMedioPagoCredito', MEDIO_PAGO_CREDITO, { root: true });
    commit('formFactura/setIndentificacionSelected', TIPO_DOCUMENTOS[0], { root: true });
    commit('formFactura/setTipoContingencias', LISTA_CONTINGENCIAS, { root: true });
    commit('formFactura/setTipoEmisions', LISTA_TIPO_EMISIONS_FILTERED, { root: true });
    commit('formFactura/setTipoEmisionSelected', TIPO_EMISION_SELECTED, { root: true });
    commit('setPuntoVentas', LISTA_PUNTO_VENTAS);
    commit('formFactura/setModalidadesFacturas', modalidadesFacturacion, { root: true });
    commit('formFactura/setTipoFactura', modalidadesFacturacion[0].id, { root: true });
    commit('formFactura/setComboAseguradora', aseguradoras, { root: true });
    commit('formFactura/setMedicos', medicos, { root: true });
    commit('formFactura/cargarComboMoneda', monedas, { root: true });
    commit('formaPagoModal/setTipoFormaPagos', FORMAS_DE_PAGO, { root: true });
    commit('formaPagoModal/setComboModalMedioPago', MEDIOS_DE_PAGO, { root: true });
    commit('addServiceModal/setCategoriaServicios', CATEGORIA_SERVICIOS, { root: true });
    commit('addServiceModal/setCategoriaServiciosSelected', CATEGORIA_SERVICIOS[0], { root: true });
    commit('formFactura/setCheckValorCero', 0, { root: true });
    commit('formaPagoModal/setMedioPagoAjusteAnticipo', MEDIO_PAGO_AJUSTE_ANTICIPO, { root: true });
  } catch (error) {
    util.showNotify(error.response.data.message, 'error');
  }
}
