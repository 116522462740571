import callIndexApi from './apis/listarClasificacion';
import callStoreApi from './apis/storeClasificacion';
import callDestroyApi from './apis/destroyClasificacion';
import callEditApi from './apis/editClasificacion';
import callUpdateApi from './apis/updateClasificacion';

export default {
  namespaced: true,
  actions: {
    callIndexApi,
    callStoreApi,
    callDestroyApi,
    callEditApi,
    callUpdateApi,
  },
};
