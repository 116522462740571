import modalHonorarioMedico from './modalHonorarioMedico';
import modalTurno1 from './modalTurno1';
import modalTurno2 from './modalTurno2';

const medicoStore = {
  modalHonorarioMedico,
  modalTurno1,
  modalTurno2,
};

export default medicoStore;
