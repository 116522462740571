// es la misma convencion que maneja el backend -- revisado el 26/04/2023 --
export const MODALIDAD_FACTURACION = Object.freeze({
  SIN_FACTURA: '', // este valor no se encuentra en la convencion del backend.
  COMPUTARIZADA: 1,
  MANUAL: 2,
  COMPUTARIZADA_EN_LINEA: 3,
  ELECTRONICA_EN_LINEA: 4,
});

export const TIPOS_VENTA = {
  VENTA_SERVICIOS: 'VS',
  VENTA_MEDICO_TERCERO: 'VM',
};

export const FORMAS_PAGO = {
  CONTADO: 1,
  CREDITO: 2,
  TARJETA: 3,
};
