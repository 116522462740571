import Vue from 'vue';
import VueRouter from 'vue-router';
import loginRoutes from '../modules/login/routes/loginRoutes';
import dashboardRoutes from '../modules/dashboard/routes/dashboardRoutes';
import calendarioRoutes from '../modules/calendario/routes/calendarioRoutes';
import aseguradoraRoutes from '../modules/aseguradora/routes/aseguradoraRoutes';
import anticipoRoutes from '../modules/anticipo/routes/anticipoRoutes';
import facturacionRoutes from '../modules/facturacion/routes/facturacionRoutes';
import pacienteRoutes from '../modules/paciente/routes/pacienteRoutes';
import medicoRoutes from '../modules/medico/routes/medicoRoutes';
import reporteRoutes from '../modules/reportes/routes/reporteRoutes';
import cotizacionRoutes from '../modules/cotizacion/routes/cotizacionRoutes';
import tratamientoRoutes from '../modules/tratamiento/routes/tratamientoRoutes';
import parametrosRoutes from '../modules/parametros/routes/parametrosRoutes';
import asistenciaMedicoRoutes from '../modules/asistenciaMedico/routes/asistenciaRoutes';
import asistenciaEnfermeriaRoutes from '../modules/asistenciaEnfermeria/routes/asistenciaRoutes';
import store from '../store/index';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    component: () => import('../views/app/index.vue'),
    redirect: '/dashboard',
    async beforeEnter(to, from, next) {
      await store.dispatch('main/getData', { root: true });
      next();
    },
    children: [
      ...dashboardRoutes,
      ...calendarioRoutes,
      ...aseguradoraRoutes,
      ...anticipoRoutes,
      ...facturacionRoutes,
      ...pacienteRoutes,
      ...medicoRoutes,
      ...reporteRoutes,
      ...cotizacionRoutes,
      ...tratamientoRoutes,
      ...parametrosRoutes,
      ...asistenciaMedicoRoutes,
      ...asistenciaEnfermeriaRoutes,
    ],
  },
  ...loginRoutes,
  {
    path: '/503',
    name: '503',
    component: () => import('../views/app/pages/errors/503.vue'),
  },
  {
    path: '/unauthorized',
    name: 'unauthorized',
    component: () => import('../views/app/pages/unauthorized.vue'),
  },
  {
    path: '*',
    component: () => import('../views/app/pages/notFound.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});
router.beforeEach((to, from, next) => {
  document.title = to.meta.title ? to.meta.title : 'Gemgloo - Clinic';
  const { isAuthenticated } = store.state.auth;
  if (to.name !== 'login' && (isAuthenticated === false)) {
    next({ name: 'login' });
  } else {
    next();
  }
});
export default router;
