import { hasPermision } from './utilidades';

export default {
  hasPermisionListarTratamiento(state) {
    const KEY = 'TRAT_LISTAR';
    const ACCION = 'listar';
    return hasPermision(state, KEY, ACCION);
  },
  hasPermisionNuevoTratamiento(state) {
    const KEY = 'TRAT_NUEVO';
    const ACCION = 'nuevo';
    return hasPermision(state, KEY, ACCION);
  },
  hasPermisionModificarTratamiento(state) {
    const KEY = 'TRAT_EDITAR';
    const ACCION = 'modificar';
    return hasPermision(state, KEY, ACCION);
  },
  hasPermisionVerTratamiento(state) {
    const KEY = 'TRAT_VER';
    const ACCION = 'ver';
    return hasPermision(state, KEY, ACCION);
  },
  hasPermisionEliminarTratamiento(state) {
    const KEY = 'TRAT_ELIMINAR';
    const ACCION = 'eliminar';
    return hasPermision(state, KEY, ACCION);
  },
  hasPermisionImprimirTratamiento(state) {
    const KEY = 'TRAT_IMPRIMIR';
    const ACCION = 'imprimir';
    return hasPermision(state, KEY, ACCION);
  },
};
