export default {
  namespaced: true,
  state: {
    listaSeguros: [],
  },
  getters: {
    getlistaSeguros: (state) => state.listaSeguros,
  },
  mutations: {
    setListaSeguros: (state, data) => { state.listaSeguros = data; },
    addItemListaSeguros: (state, item) => state.listaSeguros.push(item),
  },
};
