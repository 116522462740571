import { getField, updateField } from 'vuex-map-fields';

export default {
  namespaced: true,
  state: {
    listaTabla: [],
  },
  getters: {
    getField,
    getListaTabla: (state) => state.listaTabla,
    islistaTablaVacia(state) {
      if (state.listaTabla.length === 0) {
        return true;
      }
      return false;
    },
  },
  mutations: {
    updateField,
    setListaTabla: (state, data) => { state.listaTabla = data; },
    addItemlistaTabla: (state, item) => state.listaTabla.push(item),
  },
  actions: {
    cargarListaTablaFromEdit({ commit, rootState }, lista) {
      const UPDATED_LIST = [];
      lista.forEach((item) => {
        let medicoTempSelected = null;
        if (item.medico_id !== null && item.medico_id !== '') {
          const MEDICO_ID = parseInt(item.medico_id, 10);
          medicoTempSelected = rootState.formFactura.medicos.find((el) => el.id === MEDICO_ID);
        }
        UPDATED_LIST.push({
          tratamiento_id: item.tratamiento_id,
          cantidad: item.cantidad,
          descripcion: item.descripcion,
          fecha_turno: item.fecha_turno,
          hora_turno: item.hora_turno,
          numero_ticket: item.numero_ticket,
          numero_turno: item.numero_turno,
          turno_medico_id: item.turno_medico_id,
          id: item.id,
          medico: item.medico,
          medicoTempSelected,
          medico_id: item.medico_id,
          numero: item.numero,
          precio_unitario: item.precio_unitario,
          seguro: item.seguro,
          servicio: item.servicio,
          servicio_id: item.servicio_id,
          total: item.total,
          idRow: rootState.addServiceModal.idRow,
        });
        commit('addServiceModal/incrementarIdRow', null, { root: true });
      });
      commit('setListaTabla', UPDATED_LIST);
    },
    resetTableAndAddService({ commit }) {
      commit('setListaTabla', []);
    },
  },
};
