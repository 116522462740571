export function isBloqueado(state, KEY) {
  const ID = state.bloqueados.find((el) => el.key === KEY)?.id ?? null;
  if (ID === null) {
    return false;
  }
  const BLOQUEADO = state.userBloqueados.find((el) => el.id === ID)?.bloqueado ?? false;
  return (BLOQUEADO === 1);
}

export function hasPermision(state, KEY, accion) {
  const ID = state.paquetePrivilegios.find((el) => el.name === KEY)?.id ?? null;
  if (ID === null) {
    return false;
  }
  let isAuth = 0;
  switch (accion) {
    case 'listar':
      isAuth = state.userPrivilegios.find((el) => (
        parseInt(el.funcionalidad_id, 10) === ID
      ))?.listar ?? false;
      break;
    case 'nuevo':
      isAuth = state.userPrivilegios.find((el) => (
        parseInt(el.funcionalidad_id, 10) === ID
      ))?.nuevo ?? false;
      break;
    case 'modificar':
      isAuth = state.userPrivilegios.find((el) => (
        parseInt(el.funcionalidad_id, 10) === ID
      ))?.modificar ?? false;
      break;
    case 'ver':
      isAuth = state.userPrivilegios.find((el) => (
        parseInt(el.funcionalidad_id, 10) === ID
      ))?.ver ?? false;
      break;
    case 'eliminar':
      isAuth = state.userPrivilegios.find((el) => (
        parseInt(el.funcionalidad_id, 10) === ID
      ))?.eliminar ?? false;
      break;
    case 'imprimir':
      isAuth = state.userPrivilegios.find((el) => (
        parseInt(el.funcionalidad_id, 10) === ID
      ))?.imprimir ?? false;
      break;
    default:
      break;
  }
  return (parseInt(isAuth, 10) === 1);
}

export function redirectIfHasPermission(permiso, next) {
  if (!permiso) {
    next({ name: 'unauthorized' });
  } else {
    next();
  }
}

export function hasExtrasEmpresa(state, key) {
  return state.empresa_extras.find((el) => el.extra_id === key && el.activar === 1);
}
